/**
 * @generated SignedSource<<6ee3ae1bf21a3f8f3f77ba36196fe4b8>>
 * @relayHash 5c3ae985b26a1eed16d58636905911d1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5c3ae985b26a1eed16d58636905911d1

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainPageTopQuery$variables = {
  isInviteEvent: boolean;
};
export type MainPageTopQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MainQuerystringHandler_query">;
};
export type MainPageTopQuery = {
  response: MainPageTopQuery$data;
  variables: MainPageTopQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isInviteEvent"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageTopQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isInviteEvent",
            "variableName": "isInviteEvent"
          }
        ],
        "kind": "FragmentSpread",
        "name": "MainQuerystringHandler_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MainPageTopQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nickname",
            "storageKey": null
          },
          {
            "condition": "isInviteEvent",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inviteEventCode",
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5c3ae985b26a1eed16d58636905911d1",
    "metadata": {},
    "name": "MainPageTopQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "640ac82ae80668d62059b68292a02234";

export default node;
