/**
 * @generated SignedSource<<e605defd462628511ae7ead773c9f3a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostIsPersonalResolver$data = {
  readonly companyName: string | null | undefined;
  readonly " $fragmentType": "JobPostIsPersonalResolver";
};
export type JobPostIsPersonalResolver$key = {
  readonly " $data"?: JobPostIsPersonalResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostIsPersonalResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostIsPersonalResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyName",
      "storageKey": null
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "c56b7d075e9454ddf3ba0437c458cc22";

export default node;
