export const HOLIDAY_KEYS = {
  NEW_YEARS_DAY: 'NEW_YEARS_DAY',
  LUNAR_NEW_YEAR_DATES: 'LUNAR_NEW_YEAR_DATES',
  INDEPENDENCE_MOVEMENT_DAY: 'INDEPENDENCE_MOVEMENT_DAY',
  BUDDHA_BIRTHDAY_DATES: 'BUDDHA_BIRTHDAY_DATES',
  CHILDRENS_DAY: 'CHILDRENS_DAY',
  MEMORIAL_DAY: 'MEMORIAL_DAY',
  LIBERATION_DAY: 'LIBERATION_DAY',
  MID_AUTUMN_DATES: 'MID_AUTUMN_DATES',
  NATIONAL_FOUNDATION_DAY: 'NATIONAL_FOUNDATION_DAY',
  HANGEUL_DAY: 'HANGEUL_DAY',
  CHRISTMAS_DAY: 'CHRISTMAS_DAY',
} as const

interface HolidayType {
  key: keyof typeof HOLIDAY_KEYS
  name: string
  dates: number[] | Record<number, number[]>
  isAlterHoliday: boolean
  alterSunOnly?: boolean
}

// 신정
const NEW_YEARS_DAY = [1, 1]

// 구정
const LUNAR_NEW_YEAR_DATES = {
  2021: [2, 12],
  2022: [2, 1],
  2023: [1, 22],
  2024: [2, 10],
  2025: [1, 29],
  2026: [2, 17],
  2027: [2, 7],
  2028: [1, 27],
  2029: [2, 13],
  2030: [2, 3],
  2031: [1, 23],
  2032: [2, 11],
  2033: [1, 31],
  2034: [2, 19],
  2035: [2, 8],
  2036: [1, 28],
  2037: [2, 15],
  2038: [2, 4],
  2039: [1, 24],
  2040: [2, 12],
  2041: [2, 1],
  2042: [1, 22],
  2043: [2, 10],
  2044: [1, 30],
  2045: [2, 17],
  2046: [2, 6],
  2047: [1, 26],
  2048: [2, 14],
  2049: [2, 2],
  2050: [1, 23],
  2051: [2, 11],
  2052: [2, 1],
  2053: [2, 19],
  2054: [2, 8],
  2055: [1, 28],
  2056: [2, 15],
  2057: [2, 4],
  2058: [1, 24],
  2059: [2, 12],
  2060: [2, 2],
  2061: [1, 21],
  2062: [2, 9],
  2063: [1, 29],
  2064: [2, 17],
  2065: [2, 5],
  2066: [1, 26],
  2067: [2, 14],
  2068: [2, 3],
  2069: [1, 23],
  2070: [2, 11],
  2071: [1, 31],
  2072: [2, 19],
  2073: [2, 7],
  2074: [1, 27],
  2075: [2, 15],
  2076: [2, 5],
  2077: [1, 24],
  2078: [2, 12],
  2079: [2, 2],
  2080: [1, 22],
  2081: [2, 9],
  2082: [1, 29],
  2083: [2, 17],
  2084: [2, 6],
  2085: [1, 26],
  2086: [2, 14],
  2087: [2, 3],
  2088: [1, 24],
  2089: [2, 10],
  2090: [1, 30],
  2091: [2, 18],
  2092: [2, 7],
  2093: [1, 27],
  2094: [2, 15],
  2095: [2, 5],
  2096: [1, 25],
  2097: [2, 12],
  2098: [2, 1],
  2099: [1, 21],
}

// 삼일절
const INDEPENDENCE_MOVEMENT_DAY = [3, 1]

// 부처님 오신 날
const BUDDHA_BIRTHDAY_DATES = {
  2021: [5, 19],
  2022: [5, 8],
  2023: [5, 27],
  2024: [5, 15],
  2025: [5, 5],
  2026: [5, 24],
  2027: [5, 13],
  2028: [5, 2],
  2029: [5, 20],
  2030: [5, 9],
  2031: [5, 28],
  2032: [5, 16],
  2033: [5, 6],
  2034: [5, 25],
  2035: [5, 15],
  2036: [5, 3],
  2037: [5, 22],
  2038: [5, 11],
  2039: [4, 30],
  2040: [5, 18],
  2041: [5, 7],
  2042: [5, 26],
  2043: [5, 16],
  2044: [5, 5],
  2045: [5, 24],
  2046: [5, 13],
  2047: [5, 2],
  2048: [5, 20],
  2049: [5, 9],
  2050: [5, 28],
  2051: [5, 17],
  2052: [5, 6],
  2053: [5, 25],
  2054: [5, 15],
  2055: [5, 4],
  2056: [5, 22],
  2057: [5, 11],
  2058: [4, 30],
  2059: [5, 19],
  2060: [5, 7],
  2061: [5, 26],
  2062: [5, 16],
  2063: [5, 5],
  2064: [5, 23],
  2065: [5, 12],
  2066: [5, 1],
  2067: [5, 20],
  2068: [5, 9],
  2069: [4, 28],
  2070: [5, 17],
  2071: [5, 7],
  2072: [5, 25],
  2073: [5, 14],
  2074: [5, 3],
  2075: [5, 22],
  2076: [5, 10],
  2077: [4, 30],
  2078: [5, 19],
  2079: [5, 8],
  2080: [5, 26],
  2081: [5, 16],
  2082: [5, 5],
  2083: [5, 24],
  2084: [5, 12],
  2085: [5, 1],
  2086: [5, 20],
  2087: [5, 10],
  2088: [4, 28],
  2089: [5, 17],
  2090: [5, 7],
  2091: [5, 25],
  2092: [5, 13],
  2093: [5, 3],
  2094: [5, 21],
  2095: [5, 11],
  2096: [4, 30],
  2097: [5, 19],
  2098: [5, 8],
  2099: [5, 27],
}

// 어린이날
const CHILDRENS_DAY = [5, 5]

// 현충일
const MEMORIAL_DAY = [6, 6]

// 광복절
const LIBERATION_DAY = [8, 15]

// 추석
const MID_AUTUMN_DATES = {
  2021: [9, 21],
  2022: [9, 10],
  2023: [9, 29],
  2024: [9, 17],
  2025: [10, 6],
  2026: [9, 25],
  2027: [9, 15],
  2028: [10, 3],
  2029: [9, 22],
  2030: [9, 12],
  2031: [10, 1],
  2032: [9, 19],
  2033: [9, 8],
  2034: [9, 27],
  2035: [9, 16],
  2036: [10, 4],
  2037: [9, 24],
  2038: [9, 13],
  2039: [10, 2],
  2040: [9, 21],
  2041: [9, 10],
  2042: [9, 28],
  2043: [9, 17],
  2044: [10, 5],
  2045: [9, 25],
  2046: [9, 15],
  2047: [10, 4],
  2048: [9, 22],
  2049: [9, 11],
  2050: [9, 30],
  2051: [9, 19],
  2052: [9, 7],
  2053: [9, 26],
  2054: [9, 16],
  2055: [10, 5],
  2056: [9, 24],
  2057: [9, 13],
  2058: [10, 2],
  2059: [9, 21],
  2060: [9, 9],
  2061: [9, 28],
  2062: [9, 17],
  2063: [10, 6],
  2064: [9, 25],
  2065: [9, 15],
  2066: [10, 3],
  2067: [9, 23],
  2068: [9, 11],
  2069: [9, 29],
  2070: [9, 19],
  2071: [9, 8],
  2072: [9, 26],
  2073: [9, 16],
  2074: [10, 5],
  2075: [9, 24],
  2076: [9, 12],
  2077: [10, 1],
  2078: [9, 20],
  2079: [9, 10],
  2080: [9, 28],
  2081: [9, 17],
  2082: [10, 6],
  2083: [9, 26],
  2084: [9, 14],
  2085: [10, 3],
  2086: [9, 22],
  2087: [9, 11],
  2088: [9, 29],
  2089: [9, 19],
  2090: [9, 8],
  2091: [9, 27],
  2092: [9, 16],
  2093: [10, 5],
  2094: [9, 24],
  2095: [9, 13],
  2096: [9, 30],
  2097: [9, 20],
  2098: [9, 9],
  2099: [9, 29],
}

// 개천절
const NATIONAL_FOUNDATION_DAY = [10, 3]

// 한글날
const HANGEUL_DAY = [10, 9]

// 크리스마스
const CHRISTMAS_DAY = [12, 25]

export const HOLIDAYS: HolidayType[] = [
  {
    key: HOLIDAY_KEYS.NEW_YEARS_DAY,
    name: '신정',
    dates: NEW_YEARS_DAY,
    isAlterHoliday: false,
  },
  {
    key: HOLIDAY_KEYS.LUNAR_NEW_YEAR_DATES,
    name: '구정 (연휴)',
    dates: LUNAR_NEW_YEAR_DATES,
    isAlterHoliday: true,
    alterSunOnly: true,
  },
  {
    key: HOLIDAY_KEYS.INDEPENDENCE_MOVEMENT_DAY,
    name: '삼일절',
    dates: INDEPENDENCE_MOVEMENT_DAY,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.BUDDHA_BIRTHDAY_DATES,
    name: '부처님 오신 날',
    dates: BUDDHA_BIRTHDAY_DATES,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.CHILDRENS_DAY,
    name: '어린이날',
    dates: CHILDRENS_DAY,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.MEMORIAL_DAY,
    name: '현충일',
    dates: MEMORIAL_DAY,
    isAlterHoliday: false,
  },
  {
    key: HOLIDAY_KEYS.LIBERATION_DAY,
    name: '광복절',
    dates: LIBERATION_DAY,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.MID_AUTUMN_DATES,
    name: '추석 (연휴)',
    dates: MID_AUTUMN_DATES,
    isAlterHoliday: true,
    alterSunOnly: true,
  },
  {
    key: HOLIDAY_KEYS.NATIONAL_FOUNDATION_DAY,
    name: '개천절',
    dates: NATIONAL_FOUNDATION_DAY,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.HANGEUL_DAY,
    name: '한글날',
    dates: HANGEUL_DAY,
    isAlterHoliday: true,
  },
  {
    key: HOLIDAY_KEYS.CHRISTMAS_DAY,
    name: '크리스마스',
    dates: CHRISTMAS_DAY,
    isAlterHoliday: true,
  },
]
