import { startOfDay } from 'date-fns'
import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostStringifiedPersonalAsSoonAsPossibleResolver$key } from '@src/__generated__/JobPostStringifiedPersonalAsSoonAsPossibleResolver.graphql'
import { checkAsSoonAsPossibleWorkPeriod } from '@src/utils/jobPost'

/**
 *
 * @RelayResolver JobPost.stringifiedPersonalAsSoonAsPossible: String
 * @rootFragment JobPostStringifiedPersonalAsSoonAsPossibleResolver
 * 이웃알바 일주일 안에 stringified 필드
 */
export function stringifiedPersonalAsSoonAsPossible(
  jobPostRef: JobPostStringifiedPersonalAsSoonAsPossibleResolver$key
): string | null {
  const { workDates, isWorkTimeNegotiable, createdAt, isPersonal } = readFragment(
    graphql`
      fragment JobPostStringifiedPersonalAsSoonAsPossibleResolver on JobPost {
        workDates
        isWorkTimeNegotiable
        createdAt
        isPersonal
      }
    `,
    jobPostRef
  )

  // 조건 이웃알바
  // 조건 asap 함수 check
  // 조건 협의가능: true
  // 조건 공고 업로드한 날짜와 지정 시작일이 같아야한다.
  const isAsSoonAsPossible =
    isPersonal &&
    workDates?.length &&
    checkAsSoonAsPossibleWorkPeriod(workDates) &&
    isWorkTimeNegotiable &&
    startOfDay(new Date(createdAt)).getTime() === startOfDay(new Date(workDates[0])).getTime()

  return isAsSoonAsPossible ? '일주일 안에' : null
}
