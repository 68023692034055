import { CURRENT_YEAR } from './date'

export const CURRENT_MIN_SALARY = { 2022: 9160, 2023: 9620, 2024: 9860 }[CURRENT_YEAR] ?? 9860

/**
 * 법률 상 취직 인허증이 필요한 최소 연령 계산
 * 해당 연도 생일이 지나지 않은 경우를 기준으로 만 14세 출생연도
 */
export const YOUTH_YEAR_MIN = new Date().getFullYear() - 15

/**
 * 법률 상 취직 인허증이 필요한 최대 연령 계산
 * 해당 연도 생일이 지나지 않은 경우를 기준으로 만 19세 출생연도
 */
export const YOUTH_YEAR_MAX = new Date().getFullYear() - 20

/**
 * 도보 10분 거리에 해당하는 미터 단위 거리
 * @constant
 * @type {number}
 */
export const WALKING_DISTANCE_METER = 700

/**
 * 광고 관련 문의를 위한 고객센터 전화번호
 * @constant
 * @type {string}
 */
export const AD_ASK_TEL_NUMBER = '16449736'
