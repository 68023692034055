export function kstToISO(year: number, month: number, day: number, hour = 0, minute = 0, second = 0): Dateable {
  if (hour === 24) {
    hour = 0
    day++
  }
  const date = new Date(Date.UTC(year, month - 1, day, hour - 9, minute, second))
  return date.toISOString()
}

export const CURRENT_YEAR = new Date().getFullYear()

export const PERFECT_AUTHOR_24Q4 = {
  BENEFIT_START: kstToISO(2024, 10, 15),
  BENEFIT_END: kstToISO(2024, 12, 31),
  REVIEW_PERIOD_START: kstToISO(2024, 10, 1),
  REVIEW_PERIOD_END: kstToISO(2024, 12, 31),
}
