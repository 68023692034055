/**
 * @generated SignedSource<<2ee7915460f70e3b3e27ea69efc8da7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { isPersonal as jobPostIsPersonalResolverType } from "../graphql/resolvers/JobPostIsPersonalResolver";
// Type assertion validating that `jobPostIsPersonalResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(jobPostIsPersonalResolverType satisfies (
  rootKey: JobPostIsPersonalResolver$key,
) => boolean | null | undefined);
export type JobPostStringifiedPersonalAsSoonAsPossibleResolver$data = {
  readonly createdAt: Dateable;
  readonly isPersonal: boolean | null | undefined;
  readonly isWorkTimeNegotiable: boolean | null | undefined;
  readonly workDates: ReadonlyArray<Dateable> | null | undefined;
  readonly " $fragmentType": "JobPostStringifiedPersonalAsSoonAsPossibleResolver";
};
export type JobPostStringifiedPersonalAsSoonAsPossibleResolver$key = {
  readonly " $data"?: JobPostStringifiedPersonalAsSoonAsPossibleResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostStringifiedPersonalAsSoonAsPossibleResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobPostStringifiedPersonalAsSoonAsPossibleResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWorkTimeNegotiable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobPostIsPersonalResolver"
      },
      "kind": "RelayResolver",
      "name": "isPersonal",
      "resolverModule": require('./../graphql/resolvers/JobPostIsPersonalResolver').isPersonal,
      "path": "isPersonal"
    }
  ],
  "type": "JobPost",
  "abstractKey": null
};

(node as any).hash = "5bcc3858f926207481ce875135a0abd1";

export default node;
