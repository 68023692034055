import { last } from 'lodash'
import { compact, filter, isString, pipe } from 'lodash/fp'

import storage from '@src/api/storage'
import { IS_DEV } from '@src/config'
import { EXPERIMENT_SEGMENTS } from '@src/constants/api'
import { Experiment, ExperimentName, EXPERIMENTS, TreatmentSymbol } from '@src/constants/experiment'
import { mapIf } from '@src/utils/fp'

type ExperimentContext = {
  currentRegion: InitExperimentState['region']
  isAdmin: boolean
}

export const EXCLUDE_SUFFIX = 'exclude'
export const CONTROL_SUFFIX = 'control'
export const TREATMENT_MIDDLE = 'treatment'

export const isExcludeSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isExclude ?? true : true
}

export const isControlSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isControl ?? false : false
}

export const isTreatmentSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isTreatment ?? false : false
}

export const getSegmentsFromLocal = () => {
  return storage.getItemFromLocal<string[]>(EXPERIMENT_SEGMENTS) || []
}

export const setSegmentsAtLocal = (segments: string[]) => {
  storage.setItemAtLocal(EXPERIMENT_SEGMENTS, segments)
  return segments
}

export const makeExcludeSegment = (name: string, version: number) => `${name}-${version}-${EXCLUDE_SUFFIX}`

export const makeControlSegment = (name: string, version: number) => `${name}-${version}-${CONTROL_SUFFIX}`

export const makeTreatmentSegment = (name: string, version: number, group: TreatmentSymbol) => {
  return `${name}-${version}-${TREATMENT_MIDDLE}_${group.toLowerCase()}`
}

export const latestExperimentVersion = (name: string) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)
  if (!experiment) return 0

  return experiment.version
}

export const treatmentIndexToSymbol = (index: number): TreatmentSymbol => {
  return String.fromCharCode(65 + index) as TreatmentSymbol
}

export const treatmentSymbolToServerGroup = (symbol: TreatmentSymbol) => {
  return `TREATMENT_${symbol}`
}

export const excludeSegmentByExperimentName = (name: ExperimentName) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)
  if (!experiment) return null

  const excludeSegment = makeExcludeSegment(experiment.name, experiment.version)

  const segments = getSegmentsFromLocal()
  setSegmentsAtLocal([
    ...segments.filter((segment) => !(parseSegment(segment).name === experiment.name)),
    excludeSegment,
  ])
  return excludeSegment
}

export const makeAllSegmentsByExperimentName = (name: ExperimentName) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)

  if (!experiment) return null

  return [
    makeExcludeSegment(experiment.name, experiment.version),
    makeControlSegment(experiment.name, experiment.version),
    ...new Array(experiment.treatments.length)
      .fill(0)
      .map((_, idx) => makeTreatmentSegment(experiment.name, experiment.version, treatmentIndexToSymbol(idx))),
  ]
}

export type InitExperimentState = { region: { id?: number; name1?: string; name2?: string; name3?: string } | null }

// segment(e.g): 'test_exp-1-treatment_a or test_exp-1-exclude'
export const parseSegment = (segment: string) => {
  const tokens = segment.split('-')

  return {
    name: tokens[0],
    version: parseInt(tokens[1], 10),
    isExclude: tokens[2] === 'exclude',
    isControl: tokens[2] === 'control',
    isTreatment: tokens[2].startsWith('treatment'),
    treatmentType: tokens[2].split('_')?.[1]?.toUpperCase() as TreatmentSymbol,
  }
}

const areExperienceRegion = (
  experienceRegion: InitExperimentState['region'],
  currentRegion: InitExperimentState['region']
) => {
  const experienceRegionFullName = compact([
    experienceRegion?.name1,
    experienceRegion?.name2,
    experienceRegion?.name3,
  ]).join(' ')
  const currentRegionFullName = compact([currentRegion?.name1, currentRegion?.name2, currentRegion?.name3]).join(' ')
  return currentRegionFullName.startsWith(experienceRegionFullName)
}

const isRegionExperiment = (experiment: Experiment) => !!experiment.regions && experiment.regions.length > 0

const makeRandomExperimentSegment = (context: ExperimentContext) => (experiment: Experiment) => {
  const map = [experiment.exclude, experiment.control, ...experiment.treatments.map((t) => t.percent)].reduce(
    (acc, val, idx) => [...acc, (idx === 0 ? 0 : acc[idx - 1]) + val],
    [] as number[]
  )
  const rate = Math.floor(Math.random() * (last(map) ?? 100))

  if (context.isAdmin) {
    return experiment.treatments.length
      ? makeTreatmentSegment(experiment.name, experiment.version, 'A')
      : makeControlSegment(experiment.name, experiment.version)
  }

  const index = map.findIndex((item) => item > rate)
  switch (true) {
    case index == 0:
      return makeExcludeSegment(experiment.name, experiment.version)
    case index == 1:
      return makeControlSegment(experiment.name, experiment.version)
    case index >= 2:
    default:
      return makeTreatmentSegment(experiment.name, experiment.version, treatmentIndexToSymbol(index - 2))
  }
}

const makeRegionExperimentSegment = (context: ExperimentContext) => (experiment: Experiment) => {
  const inExperimentRegions =
    !experiment.regions || experiment.regions.some((r) => areExperienceRegion(r, context.currentRegion))

  return inExperimentRegions ? makeRandomExperimentSegment(context)(experiment) : null
}

const getEnableExperimentSegments = (context: ExperimentContext) => {
  const filterDynamicAssiginmentExperiments = filter((experiment: Experiment) => !experiment.isDynamicAssiginment)
  const makeExperimentSegment = mapIf(
    isRegionExperiment,
    makeRegionExperimentSegment(context),
    makeRandomExperimentSegment(context)
  )

  const fn = pipe(filterDynamicAssiginmentExperiments, makeExperimentSegment, compact, filter(isString))

  return fn(EXPERIMENTS)
}

const getDisableExperimentSegments = () => {
  const oldSegments = getSegmentsFromLocal()
  const currentExperimentNames = EXPERIMENTS.map((exp) => exp.name.toString())
  return oldSegments.filter((oldSegment) => !currentExperimentNames.includes(parseSegment(oldSegment).name))
}

export const initExperimentSegments = (state: InitExperimentState) => {
  const context: ExperimentContext = {
    currentRegion: state.region,
    isAdmin: storage.getIsAdmin(),
  }

  const enableSegments = getEnableExperimentSegments(context)
  const disableSegments = getDisableExperimentSegments()

  setSegmentsAtLocal(enableSegments)

  return [enableSegments, IS_DEV ? [] : disableSegments] as const
}
