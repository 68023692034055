/**
 * @generated SignedSource<<bc7b468c50d45c58632624e4f181a11c>>
 * @relayHash 3334e78ffacaffbb02abf8e89235f24c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3334e78ffacaffbb02abf8e89235f24c

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostStatus = "ACCEPTED" | "CLOSED" | "CREATED" | "DELETED" | "PENDING" | "REJECTED" | "RETURNED" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type FeedWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type UserJobPostsFilterInput = {
  status?: ReadonlyArray<JobPostStatus> | null | undefined;
};
export type MainPageContentsQuery$variables = {
  jobPostsFilter?: UserJobPostsFilterInput | null | undefined;
  topPremiumWhere: FeedWhereInput;
};
export type MainPageContentsQuery$data = {
  readonly me: {
    readonly region: {
      readonly name3: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"NewActivityBadgeAcquiredCallout_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MainPageContentsBannerSection_query" | "MainTopPremiumAdJobPosts_query" | "MainWritings_query" | "RestrictionInlineAlert_query">;
};
export type MainPageContentsQuery = {
  response: MainPageContentsQuery$data;
  variables: MainPageContentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobPostsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topPremiumWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "topPremiumWhere"
},
v4 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "jobPostsFilter"
},
v5 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "reasonTypes": [
      "DID_NOT_KEEP_PROMISE"
    ]
  }
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "name": "stringifiedSalary",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salaryType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JobPostExperiment",
        "kind": "LinkedField",
        "name": "experiment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salaryTextOverride",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salaryPayMethod",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "workplaceRegion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name2",
      "storageKey": null
    },
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "name": "hasFastResponseBadge",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthorBadge",
        "kind": "LinkedField",
        "name": "badge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "type": "FastResponseBadge",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "HoianUserAuthor",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v17 = [
  (v8/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Award",
      "kind": "LinkedField",
      "name": "perfectAuthor",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "hoianUser",
  "plural": false,
  "selections": [
    {
      "name": "receivedApplicantReviewCount",
      "args": null,
      "fragment": {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": "receivedJobReviewsCountByApplicant",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "evaluator": "APPLICANT",
                  "rating": [
                    "GREAT",
                    "GOOD",
                    "BAD"
                  ]
                }
              }
            ],
            "kind": "ScalarField",
            "name": "receivedJobReviewsCount",
            "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
          }
        ],
        "type": "User",
        "abstractKey": null
      },
      "kind": "RelayResolver",
      "storageKey": null,
      "isOutputType": true
    },
    (v18/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exposureType",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bringUpCount",
  "storageKey": null
},
v25 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v26 = [
  (v8/*: any*/),
  (v6/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": (v26/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentSlotCount",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotCount",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayState",
  "storageKey": null
},
v31 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v32 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {
        "canceled": false,
        "expired": false,
        "statusList": [
          "WAITING",
          "IN_PROGRESS",
          "HIRED"
        ]
      }
    },
    (v31/*: any*/)
  ],
  "concreteType": "ApplicationConnection",
  "kind": "LinkedField",
  "name": "applications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "applications(filter:{\"canceled\":false,\"expired\":false,\"statusList\":[\"WAITING\",\"IN_PROGRESS\",\"HIRED\"]},first:1)"
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextAvailableBringUpDate",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCash",
  "storageKey": null
},
v35 = [
  (v34/*: any*/),
  (v6/*: any*/)
],
v36 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "hoianUser",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BizUser",
              "kind": "LinkedField",
              "name": "bizUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Advertiser",
                  "kind": "LinkedField",
                  "name": "latestAdvertiser",
                  "plural": false,
                  "selections": (v35/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "HoianUserAuthor",
      "abstractKey": null
    },
    (v20/*: any*/)
  ],
  "storageKey": null
},
v37 = {
  "alias": "freeExposureStartAt",
  "args": null,
  "kind": "ScalarField",
  "name": "exposureStartAt",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "benefitReason",
  "storageKey": null
},
v39 = {
  "alias": "paidExposureStartAt",
  "args": null,
  "kind": "ScalarField",
  "name": "exposureStartAt",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refundReason",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewActivityBadgeAcquiredCallout_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainTopPremiumAdJobPosts_query"
      },
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainWritings_query"
      },
      {
        "args": [
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RestrictionInlineAlert_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MainPageContentsBannerSection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nickname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "name": "acquiredActivityBadgeCount",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserActivityBadge",
                  "kind": "LinkedField",
                  "name": "activityBadge",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        (v8/*: any*/),
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "User",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": [
                    "PENDING",
                    "ACCEPTED"
                  ]
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 2
              }
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v16/*: any*/),
                              (v19/*: any*/)
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"PENDING\",\"ACCEPTED\"]},first:2)"
          },
          {
            "alias": null,
            "args": [
              (v5/*: any*/),
              {
                "kind": "Literal",
                "name": "limit",
                "value": 1
              }
            ],
            "concreteType": "UserActionRestriction",
            "kind": "LinkedField",
            "name": "restrictedActions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": "restrictedActions(filter:{\"reasonTypes\":[\"DID_NOT_KEEP_PROMISE\"]},limit:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsOfService",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applicantTermsAgreed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "page",
            "value": 1
          },
          {
            "kind": "Literal",
            "name": "size",
            "value": 4
          },
          (v3/*: any*/)
        ],
        "concreteType": "FeedCard",
        "kind": "LinkedField",
        "name": "topFeed",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedAdvertisementInfo",
            "kind": "LinkedField",
            "name": "advertisement",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertisementCallback",
                "kind": "LinkedField",
                "name": "callback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "click",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impression",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hide",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": [
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "name": "isPerfectAuthor",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "User",
                              "kind": "LinkedField",
                              "name": "hoianUser",
                              "plural": false,
                              "selections": [
                                (v18/*: any*/),
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "HoianUserAuthor",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuthorBadge",
                        "kind": "LinkedField",
                        "name": "badge",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v19/*: any*/)
                    ],
                    "type": "HoianUserAuthor",
                    "abstractKey": null
                  },
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "author",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v25/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastBringUpDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedAt",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewCount",
                        "storageKey": null
                      },
                      (v21/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "workplaceRegion",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayUnavailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": (v26/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BusinessLicense",
                        "kind": "LinkedField",
                        "name": "businessLicense",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "hoianUser",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BizUser",
                                    "kind": "LinkedField",
                                    "name": "bizUser",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Advertiser",
                                        "kind": "LinkedField",
                                        "name": "latestAdvertiser",
                                        "plural": false,
                                        "selections": [
                                          (v23/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  {
                                    "name": "isPotentialCloseJobPostAbuser",
                                    "args": null,
                                    "fragment": {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "JobPostExtend",
                                          "kind": "LinkedField",
                                          "name": "jobPostsExtend",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "closedJobPostCount",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "User",
                                      "abstractKey": null
                                    },
                                    "kind": "RelayResolver",
                                    "storageKey": null,
                                    "isOutputType": true
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "JobPostExtend",
                                    "kind": "LinkedField",
                                    "name": "jobPostsExtend",
                                    "plural": false,
                                    "selections": [
                                      (v28/*: any*/),
                                      (v29/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "availableSlotCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rejectedBecauseOf",
                        "storageKey": null
                      },
                      (v30/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "welfare",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "consideredQualifications",
                        "storageKey": null
                      },
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "advertisementAvailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "exposureItems",
                        "plural": true,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "name": "currentExposureItem",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v6/*: any*/),
                            (v21/*: any*/),
                            (v36/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "exposureItems",
                              "plural": true,
                              "selections": [
                                (v8/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v37/*: any*/),
                                    {
                                      "alias": "freeExposureEndAt",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureEndAt",
                                      "storageKey": null
                                    },
                                    (v38/*: any*/)
                                  ],
                                  "type": "JobPostFreeExposureItem",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v39/*: any*/),
                                    {
                                      "alias": "paidExposureEndAt",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureEndAt",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": "paidExposureHours",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "exposureHours",
                                      "storageKey": null
                                    },
                                    (v40/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "paymentDueAt",
                                      "storageKey": null
                                    },
                                    (v41/*: any*/),
                                    (v38/*: any*/)
                                  ],
                                  "type": "JobPostPaidExposureItem",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "currentAdvertisement",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v6/*: any*/),
                            (v21/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "JobPostAdvertisement",
                              "kind": "LinkedField",
                              "name": "advertisements",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "budget",
                                  "storageKey": null
                                },
                                (v42/*: any*/),
                                (v21/*: any*/),
                                (v22/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "materialId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "unitId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Advertiser",
                                  "kind": "LinkedField",
                                  "name": "advertiser",
                                  "plural": false,
                                  "selections": [
                                    (v23/*: any*/),
                                    (v34/*: any*/),
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "displayStatus",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v21/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hidden",
                              "storageKey": null
                            },
                            (v30/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "engagementScore",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "businessType",
                              "storageKey": null
                            },
                            (v36/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Identification",
                              "kind": "LinkedField",
                              "name": "identification",
                              "plural": false,
                              "selections": (v17/*: any*/),
                              "storageKey": null
                            },
                            (v32/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BusinessLicense",
                              "kind": "LinkedField",
                              "name": "businessLicense",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "validatedAt",
                                  "storageKey": null
                                },
                                (v27/*: any*/),
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "exposureItems",
                              "plural": true,
                              "selections": [
                                (v8/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v37/*: any*/)
                                  ],
                                  "type": "JobPostFreeExposureItem",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v41/*: any*/),
                                    (v40/*: any*/),
                                    (v39/*: any*/)
                                  ],
                                  "type": "JobPostPaidExposureItem",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "JobPostAdvertisement",
                              "kind": "LinkedField",
                              "name": "advertisements",
                              "plural": true,
                              "selections": [
                                (v21/*: any*/),
                                (v42/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Advertiser",
                                  "kind": "LinkedField",
                                  "name": "advertiser",
                                  "plural": false,
                                  "selections": (v35/*: any*/),
                                  "storageKey": null
                                },
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "name": "isPersonal",
                              "args": null,
                              "fragment": {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/)
                                ],
                                "type": "JobPost",
                                "abstractKey": null
                              },
                              "kind": "RelayResolver",
                              "storageKey": null,
                              "isOutputType": true
                            }
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "nextAvailableBringUpTimes",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v24/*: any*/),
                            (v33/*: any*/)
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v25/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "MainWritingsList_jobPosts",
            "kind": "LinkedHandle",
            "name": "jobPosts"
          },
          {
            "alias": "log_is_biz",
            "args": [
              (v31/*: any*/)
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v26/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPostExtend",
            "kind": "LinkedField",
            "name": "jobPostsExtend",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "3334e78ffacaffbb02abf8e89235f24c",
    "metadata": {},
    "name": "MainPageContentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7433bfdeee7aa057f2bbba34bfba51c3";

export default node;
