/**
 * @generated SignedSource<<d313206075daa117dc726551e2637585>>
 * @relayHash 9e2afe950b3e04290c5f7d63bc5beef2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9e2afe950b3e04290c5f7d63bc5beef2

import { ConcreteRequest } from 'relay-runtime';
export type ExperimentSegmentEndReasonType = "CONVERTED" | "EXPERIMENT_END" | "NEW_VERSION_CREATED" | "OUTDATED_ACCESS" | "%future added value";
export type ExperimentSegmentGroup = "CONTROL" | "EXCLUDE" | "TREATMENT_A" | "TREATMENT_B" | "TREATMENT_C" | "TREATMENT_D" | "TREATMENT_E" | "TREATMENT_F" | "TREATMENT_G" | "TREATMENT_H" | "TREATMENT_I" | "TREATMENT_J" | "TREATMENT_K" | "TREATMENT_L" | "TREATMENT_M" | "TREATMENT_N" | "%future added value";
export type RegisterExperimentSegmentDataInput = {
  group: ExperimentSegmentGroup;
  name: string;
  version: number;
};
export type EndExperimentSegmentDataInput = {
  endReason: ExperimentSegmentEndReasonType;
  name: string;
  version: number;
};
export type JobsInitialDataFetcherInitExperimentMutation$variables = {
  endData: ReadonlyArray<EndExperimentSegmentDataInput>;
  registerData: ReadonlyArray<RegisterExperimentSegmentDataInput>;
};
export type JobsInitialDataFetcherInitExperimentMutation$data = {
  readonly endExperimentSegment: {
    readonly success: boolean;
  };
  readonly registerExperimentSegments: {
    readonly experimentSegments: ReadonlyArray<{
      readonly endAt: Dateable | null | undefined;
      readonly endReason: ExperimentSegmentEndReasonType | null | undefined;
      readonly group: ExperimentSegmentGroup;
      readonly name: string;
      readonly version: number;
    }>;
  };
};
export type JobsInitialDataFetcherInitExperimentMutation = {
  response: JobsInitialDataFetcherInitExperimentMutation$data;
  variables: JobsInitialDataFetcherInitExperimentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endData"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "registerData"
},
v2 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "registerData"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "group",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endReason",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "data",
      "variableName": "endData"
    }
  ],
  "concreteType": "EndExperimentSegmentResult",
  "kind": "LinkedField",
  "name": "endExperimentSegment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobsInitialDataFetcherInitExperimentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RegisterExperimentSegmentsResult",
        "kind": "LinkedField",
        "name": "registerExperimentSegments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExperimentSegment",
            "kind": "LinkedField",
            "name": "experimentSegments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JobsInitialDataFetcherInitExperimentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RegisterExperimentSegmentsResult",
        "kind": "LinkedField",
        "name": "registerExperimentSegments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExperimentSegment",
            "kind": "LinkedField",
            "name": "experimentSegments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "id": "9e2afe950b3e04290c5f7d63bc5beef2",
    "metadata": {},
    "name": "JobsInitialDataFetcherInitExperimentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6b7d1eb43c46b2f1a7fe0f26b9d30509";

export default node;
