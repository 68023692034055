import { graphql } from 'relay-runtime'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { JobPostIsPersonalResolver$key } from '@src/__generated__/JobPostIsPersonalResolver.graphql'

/**
 *
 * @RelayResolver JobPost.isPersonal: Boolean
 * @rootFragment JobPostIsPersonalResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function isPersonal(jobPostRef: JobPostIsPersonalResolver$key) {
  const jobPost = readFragment(
    graphql`
      fragment JobPostIsPersonalResolver on JobPost {
        companyName
      }
    `,
    jobPostRef
  )

  return !jobPost.companyName
}
