export type TreatmentSymbol = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N'

export type Treament = {
  group: TreatmentSymbol
  percent: number
  description: string
}

// e.g BM_TASK_FEE: 'bm_task_fee'
export const EXPERIMENT_NAMES = {
  NEAR_JOB_POSTS_SECTION: 'near_job_posts_section',
  SUGGEST_UPDATING_JOB_POST_TITLE: 'suggest_updating_job_post_title',
  BM_SOFT_ONBOARDING: 'bm_soft_onboarding',
  MAIN_FILTER_FEED: 'main_filter_feed',
  LARGE_SCALE_BUSINESS_GUIDE: 'large_scale_business_guide',
} as const

export type ExperimentName = (typeof EXPERIMENT_NAMES)[keyof typeof EXPERIMENT_NAMES]

export interface Experiment {
  name: ExperimentName
  description: string
  version: number
  exclude: number
  control: number
  treatments: Treament[]
  regions?: readonly { name1?: string; name2?: string; name3?: string }[]
  isDynamicAssiginment?: boolean
}

export const EXPERIMENTS: readonly Experiment[] = [
  {
    name: EXPERIMENT_NAMES.NEAR_JOB_POSTS_SECTION,
    description: '걸어서 10분 공고 섹션 노출 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '섹션 노출' }],
  },
  {
    name: EXPERIMENT_NAMES.SUGGEST_UPDATING_JOB_POST_TITLE,
    description: '공고 제목 업데이트 권유 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '공고 제목 업데이트 권유' }],
  },
  {
    name: EXPERIMENT_NAMES.BM_SOFT_ONBOARDING,
    description: 'BM 소프트 온보딩 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '소프트 온보딩 페이지 노출' }],
  },
  {
    name: EXPERIMENT_NAMES.MAIN_FILTER_FEED,
    description: '정확한 필터 보여주기 실험',
    version: 2,
    exclude: 0,
    control: 0,
    treatments: [{ group: 'A', percent: 100, description: '정확히 필터링된 필터 피드 노출' }],
    regions: [{ name1: '경기도' }],
  },
  {
    name: EXPERIMENT_NAMES.LARGE_SCALE_BUSINESS_GUIDE,
    description: '대규모/상시 안내 실험',
    version: 1,
    exclude: 0,
    control: 0,
    treatments: [
      { group: 'A', percent: 50, description: '날짜/예산 조합형 광고 생성' },
      { group: 'B', percent: 50, description: '단일 상품 선택형 광고 생성' },
    ],
  },
] as const
